<template>
  <backoffice-container>
    <b-card
      no-body
      :class="isMobile ? 'p-2' : 'p-3'"
      :style="
        isMobile
          ? 'min-height: calc(100vh - 100px)'
          : 'min-height: calc(100vh - 125px)'
      "
    >
      <div class="d-flex flex-wrap justify-content-between ml-50 mb-2">
        <h4>{{ $t("backoffice.invoices.title") }}</h4>
        <b-button v-b-modal.new-invoice variant="primary" class="ml-1">
          {{ $t("backoffice.invoices.new-invoice") }}
        </b-button>
      </div>
      <div v-if="isLoading" class="h-100 d-flex align-items-center">
        <b-spinner class="d-block mx-auto mt-3" variant="primary" />
      </div>
      <div v-else>
        <div v-if="items && items.length > 0">
          <div
            v-for="(item, index) in Object.values(filteredItems)"
            v-show="items && items.length > 0"
            :key="index"
            class="w-100"
          >
            <div
              v-if="showSpace"
              :class="isMobile ? '' : 'align-items-center'"
              class="d-flex justify-content-between"
            >
              <b-link
                class="text-dark d-flex w-100"
                :class="isMobile ? '' : 'align-items-center'"
                :href="getCollectiveUrl(item.community)"
                target="_blank"
              >
                <collective-logo :logo="item.community.logoURL" :size="30" />
                <div class="ml-50">
                  <p class="mb-0 font-weight-bold">
                    {{ translate(item.community.name) }}
                  </p>
                  <p
                    v-if="item.community.headline"
                    class="small mb-0 mt-n25 text-muted"
                  >
                    {{ translate(item.community.headline) }}
                  </p>
                </div>
              </b-link>
              <div
                class="d-flex"
                :class="
                  isMobile
                    ? 'flex-column justify-content-center'
                    : ' aling-items-center'
                "
              >
                <b-badge
                  variant="light-primary"
                  class="font-weight-normal pointer"
                  :class="isMobile ? 'invoice-1 mt-50 mb-50' : 'invoice-0 mr-50'"
                  @click="downloadPayments(item.community)"
                >
                  <span v-if="isLoadingButton">
                    <b-spinner small class="spinner" /> {{ $t("loading") }}
                  </span>
                  <span v-else>
                    <feather-icon icon="DownloadIcon" class="mr-25" />
                    {{ $t("backoffice.members.export") }}
                  </span>
                </b-badge>
                <b-badge
                  pill
                  :class="isMobile ? 'invoice-0' : 'invoice-1'"
                  :variant="getColorSpace(item.community.mainType)"
                  class="text-capitalize check-button"
                >
                  {{ getStatusSpace(item.community.mainType) }}
                </b-badge>
              </div>
            </div>
            <app-scroll>
              <!-- Invoices table for mobile version -->
              <invoices-table-mobile
                v-if="isMobile"
                :busy="isLoading || isLoadingNextPage"
                :item="item"
                @isSpaceNameShown="(response) => (showSpace = response)"
              />
              <!-- Invoices table for desktop version -->
              <invoices-table-web
                v-else
                :busy="isLoading || isLoadingNextPage"
                :item="item"
              />
            </app-scroll>
          </div>
        </div>
        <div
          v-else
          class="d-flex placeholder align-item-center justify-content-center flex-column my-2"
          style="min-height: 45vh"
        >
          <b-img :src="paymentsPlaceholder" class="placeholder-img" center />
          <div class="mt-2 text-center">
            <p class="text-primary font-weight-500">
              {{
                $t("available.message", {
                  itemName: $t("backoffice.invoices.title"),
                })
              }}
            </p>
          </div>
        </div>
      </div>
      <loading-modal
        v-model="isLoadingButton"
        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
      />

      <!-- New invoice modal -->
      <b-modal
        id="new-invoice"
        class="invoices-modal"
        :title="$t('backoffice.invoices.new-invoice')"
        :ok-title="$t('form.actions.save')"
        ok-only
        centered
        size="lg"
        :ok-disabled="isDisabled"
        no-close-on-backdrop
        @hidden="resetForm()"
        @shown="atFormShown()"
        @ok="saveInvoice()"
      >
        <template #modal-header>
          <language-selector-header
            :title="
              isEditing
                ? $t('backoffice.invoices.edit-invoice')
                : $t('backoffice.invoices.create-invoice')
            "
            @closeModal="closeModal"
            @selectLanguage="(language) => (selectedLanguage = language)"
          />
        </template>
        <!-- Reference -->
        <b-form-group class="flex-grow-1 mb-2 mt-50">
          <b-row>
            <!-- Reference -->
            <b-col class="col-xs-12">
              <label for="invoice-ref" class="font-weight-bold">
                {{ $t("backoffice.invoices.fields.ref") }}
              </label>
              <b-form-input
                v-model="currentInvoice.ref"
                label-for="invoice-ref"
              />
            </b-col>
          </b-row>
        </b-form-group>
        <!-- Dates -->
        <b-form-group class="flex-grow-1 mb-2 mt-50">
          <b-row>
            <!-- Issuing Date -->
            <b-col class="col-xs-6">
              <label for="invoice-date" class="font-weight-bold">
                {{ $t("backoffice.invoices.fields.issuing-date") }}
              </label>
              <VueCtkDateTimePicker
                id="date"
                v-model="currentInvoice.date"
                name="date"
                :locale="locale"
                format="YYYY-MM-DD"
                formatted="ll"
                :label="$t('backoffice.invoices.fields.issuing-date')"
                label-for="invoice-date"
                color="var(--primary)"
                no-button-now
                only-date
                button-color="var(--primary)"
              />
            </b-col>
            <!-- Due Date -->
            <b-col class="col-xs-6">
              <label for="invoice-date" class="font-weight-bold">
                {{ $t("backoffice.invoices.fields.due-date") }}
              </label>
              <VueCtkDateTimePicker
                id="date"
                v-model="currentInvoice.dueDate"
                name="date"
                :locale="locale"
                format="YYYY-MM-DD"
                formatted="ll"
                :label="$t('backoffice.invoices.fields.due-date')"
                label-for="invoice-date"
                color="var(--primary)"
                no-button-now
                only-date
                button-color="var(--primary)"
              />
            </b-col>
          </b-row>
        </b-form-group>
        <!-- Amount and currency -->
        <b-form-group class="flex-grow-1 mb-2">
          <b-row>
            <!-- Amount -->
            <b-col class="col-xs-6">
              <label for="invoice-price" class="font-weight-bold">
                Amount
              </label>
              <b-form-input
                v-model="currentInvoice.price"
                type="number"
                label-for="invoice-price"
              />
            </b-col>
            <!-- Currency -->
            <b-col class="col-xs-6">
              <label for="invoice-currency" class="font-weight-bold">
                Currency
              </label>
              <b-form-select
                id="invoice-currency"
                v-model="currentInvoice.currencyId"
                :options="currencyCode"
                placeholder="Select a Currency..."
                label-type="text"
                @keydown.enter.prevent="onEnter"
              />
            </b-col>
          </b-row>
        </b-form-group>
        <!-- Apps -->
        <!--b-form-group>
          <label for="rol-name" class="font-weight-bold">
             {{ $t('common.apps') }}
          </label>
          <v-select
            v-model="currentInvoice.modelType"
            class="select-control p-0"
            :placeholder="$t('form.select-multiple-placeholder')"
            clearable
            :multiple="false"
            :label="locale"
            :searchable="false"
            :get-option-label="getOptionsLabel"
            :options="modelOptions"
          />
        </b-form-group-->
        <!-- Organization Sender / Emitter -->
        <b-form-group>
          <label for="rol-name" class="font-weight-bold">
             {{ $t('backoffice.invoices.fields.sender') }}
          </label>
          <v-select
            v-if="currentCollective.mainType !== 'Organization'"
            v-model="currentInvoice.paymentEmitterKey"
            class="select-control p-0"
            :placeholder="$t('form.select-multiple-placeholder')"
            clearable
            :multiple="false"
            :label="locale"
            :searchable="false"
            :get-option-label="(label) => translate(label.name)"
            :options="senderOrganizationsOptions"
          />
          <div v-else>
            {{ translate(currentCollective.name) }}
          </div>
        </b-form-group>
        <!-- Organization Receptor / Collector -->
        <b-form-group>
          <label for="rol-name" class="font-weight-bold">
             {{ $t('backoffice.invoices.fields.receptor') }}
          </label>
          <v-select
            v-model="paymentCollector"
            class="select-control p-0"
            :placeholder="$t('form.select-multiple-placeholder')"
            clearable
            :multiple="false"
            :label="locale"
            :searchable="false"
            :get-option-label="(label) => translate(label.name)"
            :options="receptorOrganizationsOptions"
          />
        </b-form-group>
        <!-- Invoice date -->
        <!--b-form-group>
          <label for="rol-name" class="font-weight-bold">
             {{ $t('backoffice.invoices.fields.issuing-date') }}
          </label>
          
        </b-form-group-->
        <!-- Invoice file -->
        <!--b-form-group>
          <label for="rol-name" class="font-weight-bold">
             {{ $t('backoffice.invoices.fields.file') }}
          </label>
          
        </b-form-group-->
        <!-- Attach file -->
        <b-form-group>
          <label for="rol-name" class="font-weight-bold">
             {{ $t('backoffice.invoices.fields.file') }}
          </label>
          <file-upload
            v-model="attachments"
            class="mw-100"
            cancelable
            type="imageOrPdf"
            :multiple="false"
            :placeholder="$t('backoffice.invoices.fields.attach')"
            :drop-placeholder="$t('backoffice.invoices.fields.drop-placeholder')"
          />
        </b-form-group>
      </b-modal>
    </b-card>
  </backoffice-container>
</template>

<script>
import BackofficeContainer from "@/backoffice/components/BackofficeContainer.vue";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import PaymentsPlaceholder from "@/assets/images/placeholders/light/invoices.svg";
import AppScroll from "@/@core/components/app-scroll/AppScroll.vue";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import CollectiveLogo from "@core/components/collective-logo/CollectiveLogo.vue";
import InvoicesTableWeb from "@/backoffice/components/invoices/InvoicesTableWeb.vue";
import InvoicesTableMobile from "@/backoffice/components/invoices/InvoicesTableMobile.vue";
import { getCollectiveUrl } from "@core/utils/collective";
import LoadingModal from "@core/components/modal/LoadingModal.vue";
import LanguageSelectorHeader from "@core/components/modal/LanguageSelectorHeader.vue";
import vSelect from 'vue-select';
import Service from '@/config/service-identifiers';
import { MainType } from '@copernicsw/community-common';
import FileUpload from '@core/components/files/FileUpload.vue';

export default {
  name: "BackofficeInvoices",
  components: {
    BackofficeContainer,
    AppScroll,
    CollectiveLogo,
    InvoicesTableWeb,
    InvoicesTableMobile,
    LoadingModal,
    LanguageSelectorHeader,
    FileUpload,
    vSelect
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      isLoading: false,
      isLoadingNextPage: false,
      isLoadingButton: false,
      showSpace: true,
      currencyCode: [{ value: 45, text: 'Select a Currency...' }],
      paymentCollector: null,
      attachments: null,
      currentInvoice: {
        ref: null,
        // modelType: "organization",
        // modelKey: null,
        // fromOrgKey: null,
        paymentEmitterType: "community",
        paymentEmitterKey: null,
        paymentCollectorType: "community",
        paymentCollectorKey: null,
        priceId: null,
        subscriptionPlanId: null,
        mainCommunitySlug: null,
        currencyId: 45,       //euro
        currencyPriceItemsWithoutFee: 0,
        currencyPriceItemsFee: 0,
        currencyAppFee: 0,
        currencyPriceTotal: 0,
        status: "succeeded",  //succeeded, pending, failed, refunded, canceled, requires_action, processing, requires_payment_method
        createdByUserId: null,
        //Billing
        hasBilling: 0,
        billingType: null,  //company/particular
        billingName: null,
        billingSurname: null,
        billingEmail: null,
        billingPhoneNumber: null,
        billingNifNieCif: null,
        billingCountryId: null,
        billingProvince: null,
        billingPostalCode: null,
        billingLocation: null,
        billingAddress1: null,
        billingAddress2: null,
      },
      statusOptions: {
        'succeeded': 'Succeeded',
        'pending': 'Pending',
        'processing': 'Processing',
        'failed': 'Failed',
        'refunded': 'Refunded',
        'canceled': 'Cancelled',
        'requires_action': 'Requires action',
        'requires_payment_method': 'Requires invoice method'
      },
      billingTypeOptions: {
        'company': 'Company',
        'particular': 'Particular'
      },
      isValid: null,
      selectedLanguage: this.currentLocale,
      isEditing: false,
    };
  },
  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective?.slug ? this.$store.getters.currentCollective : null;
    },
    mainCollective() {
      return this.$store.getters.mainCollective?.slug ? this.$store.getters.mainCollective : null;
    },
    mainSpace() {
      return this.mainCollective ?? this.currentCollective;
    },
    paymentsPlaceholder() {
      return PaymentsPlaceholder;
    },
    itemsData() {
      return this.$store.getters.invoices?.[this.currentCollective.key];
    },
    items() {
      return this.itemsData?.unpaginated;
    },
    isMobile() {
      return window.innerWidth < 700;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    senderOrganizationsOptions() {
      const allOrgs = this.$store.getters.communitiesOthers?.organizations?.unpaginated;
      if(allOrgs && this.currentCollective?.mainType === 'Organization'){
        // Select the current org key
        this.currentInvoice.paymentEmitterKey = this.currentCollective.key;
        // Map all orgs to send only the current
      console.log('senderOrganizationsOptions', [this.currentCollective]);
        return [this.currentCollective];
      }
      return allOrgs;
    },
    receptorOrganizationsOptions() {
      const allOrgs = this.$store.getters.communitiesOthers?.organizations?.unpaginated;
      if(allOrgs && this.currentCollective?.mainType === 'Organization'){
        const allOrgsWithoutCurrent = [];
        allOrgs.forEach(org => {
          if(org.key !== this.currentCollective.key){
            allOrgsWithoutCurrent.push(org);
          }
        });
        return allOrgsWithoutCurrent;
      }
      console.log('receptorOrganizationsOptions', allOrgs);
      return allOrgs;
    },
    filteredItems() {
      const filteredItems = {};
      this.items?.forEach((item) => {
        if (item.community?.key) {
          if (!Object.keys(filteredItems).includes(item.community.key)) {
            filteredItems[item.community.key] = {
              community: item.community,
              invoices: [],
              totals: {
                price: 0,
                real: 0,
                discount: 0,
              },
            };
            filteredItems[item.community.key].invoices.push(item);

            filteredItems[item.community.key].totals.price = item.price;
            if (item.hasDiscount) {
              filteredItems[item.community.key].totals.real =
                item.price + item.discountAbsolute;
              filteredItems[item.community.key].totals.discount =
                item.discountAbsolute;
            } else {
              filteredItems[item.community.key].totals.real = item.price;
            }
          } else {
            filteredItems[item.community.key].invoices.push(item);
            filteredItems[item.community.key].totals.price += item.price;
            if (item.hasDiscount) {
              filteredItems[item.community.key].totals.real +=
                item.price + item.discountAbsolute;
              filteredItems[item.community.key].totals.discount +=
                item.discountAbsolute;
            } else {
              filteredItems[item.community.key].totals.real += item.price;
            }
          }
        } else {
          console.log("This item does not have a linked community:", item);
        }
      });
      return filteredItems;
    },
    isDisabled() {
      return false;
    },
    modelOptions() {
      const modelTypesObj = this.$store.getters.modelTypes;
      const notApply = [
        'chat-individual',
        'chat-group',
        'chat',
        'map',
        'calendar',
        'meeting',
        'auditorium',
        'workspace',
        'community',
        'analytic',
        'invoice',
        'classifier',
        'custom-field',
        'live-streaming'
      ];
      let newObj = [];
      //use only correct apps
      modelTypesObj.forEach((app) => {
        if(!notApply.includes(app.addonSingular)){
          newObj.push(app);
        }
      });
      return newObj;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
  },
  async created() {
    //console.log('this.mainSpace', this.mainSpace);
    await this.fetchData();
    await this.fetchOrganizations();  //TODO: better on open modal
    //console.log('this.organizationsOptions', this.organizationsOptions);
    await this.fetchCurrencies();
    // If it is an org:
    console.log('this.currentCollective?.mainType', this.currentCollective?.mainType);
    if(this.currentCollective?.mainType === 'Organization'){
      console.log('Setting fromOrgKey to current space key')
      this.currentInvoice.paymentEmitterKey = this.currentCollective.key;
    }
  },
  methods: {
    async fetchData(force = false) {
      this.isLoading = true;
      const requestConfig = {};
      if (this.currentCollective.parentKey) {
        requestConfig.forCommunity = true;
      } else {
        requestConfig.forMain = true;
      }
      await this.$store.dispatch("getItems", {
        itemType: "invoices",
        storedKey: this.currentCollective.key,
        page: 1,
        perPage: 20,
        forceAPICall: force,
        requestConfig,
      });
      this.isLoading = false;
    },
    translate(field) {
      return translateTranslationTable(this.currentLocale,field);
      //return translateTranslationTable(this.selectedLanguage, field);
    },
    async downloadPayments(community) {
      this.isLoadingButton = true;
      const response = await this.$store.dispatch("exportFiles", {
        itemType: "/backoffice/invoices/export",
        requestConfig: {
          communitySlug: community.slug,
        },
      });
      setTimeout(this.csv(response, community), 10000);
      this.isLoadingButton = false;
    },
    csv(response, community) {
      const hiddenElement = document.createElement("a");
      hiddenElement.href = response.data.file;
      hiddenElement.target = "_blank";
      hiddenElement.download = `${this.translate(community.name)}Payments.csv`;
      hiddenElement.click();
    },
    getMainType(mainTypeId) {
      // console.log('mainTypeId', mainTypeId);
      // console.log('getMainType MainType[mainTypeId]', MainType[mainTypeId]);
      return MainType[mainTypeId];
    },
    getStatusSpace(mainType) {
      // console.log('mainType', mainType);
      if(!mainType){
        return 'Space';
      }
      const type = {
        Community: this.$t("collectives.items.Community"),
        Subcommunity: this.$t("collectives.items.Subcommunity"),
        Event: this.$t("collectives.items.Event"),
        Section: this.$t("collectives.items.Section"),
        Course: this.$t("collectives.items.Course"),
        Workspace: this.$t("collectives.items.Workspace"),
        Challenge: this.$t("collectives.items.Challenge"),
        Organization: this.$t("collectives.items.Organization"),
        Service: this.$t("collectives.items.Service"),
      };
      return type[mainType][0].toUpperCase() + type[mainType].substring(1);
    },
    getColorSpace(invoice) {
      if(!invoice){
        return "info";
      }
      const color = {
        0: "light",
        1: "light-warning",
        2: "warning",
        3: "light-success",
        4: "light-danger",
        5: "light-dark",
        6: "secondary",
        7: "light-info",
        8: "info",
        81: "dark",
        Community: "primary",
        Subcommunity: "light-info",
        Event: "danger",
        Section: "success",
        Course: "info",
        Workspace: "dark",
        Challenge: "secondary",
        Organization: "light-info",
        Service: "light-warning",
      };
      return color[invoice];
    },
    getCollectiveUrl(collective) {
      return `${getCollectiveUrl(collective)}/welcome`;
    },
    resetForm() {
      this.currentInvoice = {
        ref: null,
        // modelType: "organization",
        // fromOrgKey: null,
        // modelKey: null,
        paymentEmitterType: "community",
        paymentEmitterKey: null,
        paymentCollectorType: "community",
        paymentCollectorKey: null,
        priceId: null,
        subscriptionPlanId: null,
        mainCommunitySlug: null,
        currencyId: 45,       //euro
        currencyPriceItemsWithoutFee: 0,
        currencyPriceItemsFee: 0,
        currencyAppFee: 0,
        currencyPriceTotal: 0,
        status: "succeeded",  //succeeded, pending, failed, refunded, canceled, requires_action, processing, requires_payment_method
        createdByUserId: null,
        //Billing
        hasBilling: 0,
        billingType: null,  //company/particular
        billingName: null,
        billingSurname: null,
        billingEmail: null,
        billingPhoneNumber: null,
        billingNifNieCif: null,
        billingCountryId: null,
        billingProvince: null,
        billingPostalCode: null,
        billingLocation: null,
        billingAddress1: null,
        billingAddress2: null,
      };
      this.isValid = null;
    },
    closeModal() {
      this.$bvModal.hide("new-invoice");
    },
    atFormShown() {
      return;
    },
    async saveInvoice() {
      try {
        //console.log("this.currentInvoice", this.currentInvoice);
        // const invoice = {
        //   name: this.currentInvoice.name,
        //   ref: this.currentInvoice.ref,
        //   price: this.currentInvoice.price,
        //   modelType: Array.isArray(this.currentInvoice.modelType)
        //     ? this.currentInvoice.modelType.map(
        //         (item) => item.addonSingular
        //       )
        //     : "",
        //   type: null,
        //   mainSpaceSlug: this.mainCollective.slug,
        // };

        // MODEL TYPE Y MODEL KEY en GentBorn y Intercontinental Alliance habla de quien está pagando, quien emite la transacción
        // COLLECTOR habla de quién está recibiendo el pago
        // this.currentInvoice.fromOrgKey = this.currentInvoice.fromOrgKey?.key;
        // this.currentInvoice.modelKey = this.currentInvoice.fromOrgKey?.key;
        //console.log("this.currentInvoice.modelKey", this.currentInvoice.modelKey);
        // this.currentInvoice.paymentCollectorKey = this.currentInvoice.modelKey; 
        this.currentInvoice.paymentCollectorKey = this.paymentCollector?.key;
        this.currentInvoice.mainSpaceSlug = this.mainSpace.slug;
        this.currentInvoice.mainCommunitySlug = this.mainSpace.slug;
        if(this.currentInvoice.paymentEmitterKey?.key){
          // It is an object, we need to save only the key
          this.currentInvoice.paymentEmitterKey = this.currentInvoice.paymentEmitterKey.key;
        }
        console.log('creating invoice with currentInvoice data:', this.currentInvoice)
        await this.$store.dispatch("createItem", {
          item: {
            itemType: "/invoices",
            customName: "paymentsBO",
            requestConfig: {
              ...this.currentInvoice,
              //...invoice,
            },
          },
          file: this.attachments,
        });
        this.notifySuccess(
          this.$t("backoffice.invoices.messages.create-success")
        );
        await this.fetchData();
      } catch {
        this.notifyError(this.$t("backoffice.invoices.messages.create-error"));
      }
      //TODO: Delete next when the previous works fine...
      window.location.reload();
    },
    getLabel(labelObj) {
      return translate(labelObj.name);
    },
    getOptionsLabel() {
      // Only for organizations
      return this.organizationsOptions.map((organization) => this.translate(organization.name));
      //return this.organizationsOptions.filter((organization) => this.translate(organization.name));

      // General:
      // if(!this.modelOptions || this.modelOptions.length <= 0){
      //   return null;
      // }
      // return (a) => a.addon[this.currentLocale] || Object.values(a.addon)[0];
    },
    async fetchOrganizations() {
      // console.log('Fetching organizations with communities simply endpoint')
      //this.currentType = this.typeKey ? this.typeKey : this.communitySlug;
      const requestConfig = {
          communityParentSlug: this.mainSpace.slug,
          // communitySlug: this.mainSpace.slug,
          invoiceByDate: -1,
          checkStatus: true,
          isOrganization: true,
        };
        console.log('requestConfig', requestConfig);
      return await this.$store.dispatch('getItems', {
        itemType: "communities/simply",
        customName: "communitiesOthers",
        storedKey: "organizations",
        page: 1,
        forceAPICall: true,
        perPage: 16,
        requestConfig: requestConfig,
      });
    },
    async fetchCurrencies() {
      //console.log('Fetching currency codes...');
      const response = await this.$store.$service[Service.BackendClient].get(
        'currencies',
        {
          params: {
            communityKey: this.currentCollective.key,
            count: 164,
            forceAPICall: true,
          },
        },
      );
      const currencyArray = response.data.data;
      for (const indexCurrency in currencyArray) {
        //console.log('indexCurrency', indexCurrency);
        const newCurrency = {
          value: currencyArray[indexCurrency].id,
          text: `${currencyArray[indexCurrency].ISO} (${currencyArray[indexCurrency].symbol})`,
          ...currencyArray[indexCurrency],
        };
        this.currencyCode.push(newCurrency);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.placeholder-img {
  width: 180px;
  display: block;
}

.invoices-modal {
  max-width: 90% !important;
  width: 90% !important;
}
</style>
