var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{attrs:{"hover":"","busy":_vm.busy,"items":_vm.item.invoices,"fields":_vm.fieldsList,"responsive":"","sticky-header":'calc(100vh - 330px)'},scopedSlots:_vm._u([{key:"cell(user)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{staticClass:"d-flex align-items-center pl-1 ml-25 mt-25",staticStyle:{"min-width":"150px"}},[[_c('b-link',{staticClass:"text-dark d-flex align-items-center",on:{"click":function($event){return _vm.fetchMembers(item.paidByMember)}}},[_c('div',[(item.paidByMember)?_c('user-avatar',{attrs:{"user":item.paidByMember,"size":"24"}}):_c('user-avatar',{attrs:{"user":{},"size":"24"}})],1),_c('div',{staticClass:"ml-50"},[(item.paidByMember)?_c('p',{staticClass:"font-weight-extrabold mb-0"},[_vm._v(" "+_vm._s(((item.paidByMember.name) + " " + (item.paidByMember.surname)))+" ")]):_c('span',{staticClass:"text-muted"},[_vm._v("---")])])])]],2)]}},{key:"cell(sender)",fn:function(ref){
var item = ref.item;
return [_c('b-link',{attrs:{"href":_vm.getCollectiveUrl(item.emitter),"target":"_blank"}},[_c('div',{staticClass:"text-dark w-100 h-100 d-flex pl-1 ml-50 align-items-center",staticStyle:{"min-width":"150px"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.getTranslatedName(item.emitter))+" ")])])])]}},{key:"cell(collector)",fn:function(ref){
var item = ref.item;
return [_c('b-link',{attrs:{"href":_vm.getCollectiveUrl(item.collector),"target":"_blank"}},[_c('div',{staticClass:"text-dark w-100 h-100 d-flex pl-1 ml-50 align-items-center",staticStyle:{"min-width":"150px"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.getTranslatedName(item.collector))+" ")])])])]}},{key:"cell(community)",fn:function(ref){
var item = ref.item;
return [_c('b-link',{attrs:{"href":_vm.getCollectiveUrl(item.community),"target":"_blank"}},[_c('div',{staticClass:"text-dark w-100 h-100 d-flex pl-1 ml-50 align-items-center",staticStyle:{"min-width":"150px"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.getTranslatedName(item.community))+" ")])])])]}},{key:"cell(price)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w-100 h-100 d-flex align-items-center justify-content-end",staticStyle:{"min-width":"120px"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.price.toFixed(2))+" ")])])]}},{key:"cell(real)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w-100 h-100 d-flex align-items-center justify-content-end",staticStyle:{"min-width":"120px"}},[(item.hasDiscount)?_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s((item.price + item.discountAbsolute).toFixed(2))+" ")]):_c('p',[_vm._v(" "+_vm._s(item.price.toFixed(2))+" ")])])]}},{key:"cell(discount)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w-100 h-100 d-flex align-items-center justify-content-end",staticStyle:{"min-width":"120px"}},[(item.hasDiscount)?_c('p',{staticClass:"mb-0"},[_vm._v(" - "+_vm._s(item.discountAbsolute.toFixed(2))+" ")]):_c('p',{staticClass:"mb-0"},[_vm._v("---")])])]}},{key:"cell(currency)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w-100 h-100 d-flex pl-1 ml-50 align-items-center"},[_c('p',[_vm._v(" "+_vm._s(_vm.getCurrencyLabel(item.currency))+" ")])])]}},{key:"cell(ref)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w-100 h-100 d-flex pl-1 ml-50 align-items-center"},[_c('p',[_vm._v(_vm._s(item.ref))])])]}},{key:"cell(date)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w-100 h-100 d-flex pl-1 ml-50 align-items-center",staticStyle:{"min-width":"160px"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.getTransactionDate(item.date))+" ")])])]}},{key:"cell(duedate)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w-100 h-100 d-flex pl-1 ml-50 align-items-center",staticStyle:{"min-width":"160px"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.getTransactionDate(item.dueDate))+" ")])])]}},{key:"cell(createdat)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w-100 h-100 d-flex pl-1 ml-50 align-items-center",staticStyle:{"min-width":"160px"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.getTransactionDate(item.createdAt))+" ")])])]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center"},[(item.status)?_c('b-badge',{staticClass:"text-capitalize mb-0 ml-1 mt-75 check-button",attrs:{"pill":"","variant":_vm.getColor(item.status)}},[_vm._v(" "+_vm._s(_vm.getStatus(item.status))+" ")]):_c('p',{staticClass:"text-muted mb-0 ml-2 pl-75 mt-75"},[_vm._v("---")])],1)]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('feather-icon',{staticClass:"text-primary ml-2 cursor-pointer",attrs:{"icon":"Edit2Icon","size":"18","disabled":""},on:{"click":function($event){return _vm.handleEdit(item)}}}),_c('b-modal',{attrs:{"id":"edit-invoice-form","title":_vm.$t('backoffice.invoices.edit'),"ok-title":_vm.$t('form.actions.save'),"ok-only":""},on:{"ok":function($event){return _vm.saveEditedTransaction()}},model:{value:(_vm.isModalEditOpened),callback:function ($$v) {_vm.isModalEditOpened=$$v},expression:"isModalEditOpened"}},[_c('div',[_vm._v("Coming soon... work in progress.")])]),_c('feather-icon',{staticClass:"text-primary cursor-pointer ml-1",attrs:{"icon":"Trash2Icon","size":"18"},on:{"click":function($event){return _vm.handleDelete(item)}}}),_c('unshare-item-modal',{attrs:{"model-type":"invoice"},on:{"remove-item":function($event){return _vm.deleteItem(_vm.invoice.key)},"modal-invisible":function($event){_vm.isDeleteModalVisible = false}},model:{value:(_vm.isDeleteModalVisible),callback:function ($$v) {_vm.isDeleteModalVisible=$$v},expression:"isDeleteModalVisible"}}),(item.fileURL)?_c('a',{staticClass:"ml-1",attrs:{"href":'https://uploadscdn.nectios.com/'+item.fileURL,"target":"_blank"}},[_c('feather-icon',{attrs:{"icon":"DownloadIcon","size":"20"}})],1):_vm._e()],1)]}},(_vm.showTotals)?{key:"custom-foot",fn:function(ref){
var columns = ref.columns;
return _vm._l((columns),function(columnIndex){return _c('b-td',{key:columnIndex,staticClass:"totals"},[(columnIndex === 1)?_c('p',{staticClass:"font-weight-extrabold mb-0 font-medium-2 ml-2 text-white"},[_vm._v(" Totals ")]):_vm._e(),([5, 6, 7].includes(columnIndex))?_c('div',{staticClass:"w-100 h-100 d-flex align-items-center justify-content-end"},[(columnIndex === 3)?_c('p',{staticClass:"mb-0 text-white"},[_vm._v(" "+_vm._s(_vm.item.totals.price.toFixed(2))+" "+_vm._s(_vm.item.invoices[0].currency.symbol)+" ")]):_vm._e(),(columnIndex === 4)?_c('p',{staticClass:"mb-0 text-muted"},[_vm._v(" "+_vm._s(_vm.item.totals.real.toFixed(2))+" "+_vm._s(_vm.item.invoices[0].currency.symbol)+" ")]):_vm._e(),(columnIndex === 5)?_c('p',{staticClass:"mb-0 text-muted"},[_vm._v(" - "+_vm._s(_vm.item.totals.discount.toFixed(2))+" "+_vm._s(_vm.item.invoices[0].currency.symbol)+" ")]):_vm._e()]):_vm._e()])})}}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }